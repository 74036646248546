<template>
    <v-content class="block" style="margin-top: 150px !important">
        <v-row justify="center">
            <v-col cols="9">
                <v-card class="mx-auto pa-10 text-center" width="800" elevation="10" shaped>
                    <v-img class="mx-auto" src="@/assets/img/404.png" lazy-src="@/assets/img/404.png" aspect-ratio="1.5" width="400"> </v-img>
                    <p class="textError">Sorry, it looks like the page get lost</p>
                    <v-btn rounded class="mx-auto center" color="#c40f0f" width="180" style="color: white" @click="sendToHome">Back to Home</v-btn>
                </v-card>
            </v-col>
        </v-row>
    </v-content>
</template>
<script>
export default {
    methods: {
        sendToHome(){
            this.$router.push({ name: 'home' })
        }
    }
}
</script>
<style scoped>
.textError{
    color: #000000;
    font-family: 'Open Sans';
    font-size: 64px;
    font-style: normal;
    text-align: center;
    top: 577px;
}
</style>